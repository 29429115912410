import React, { useCallback, useState } from 'react'
import { queryCache, useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import SheetUploader from 'design/elements/SheetUploader'
import Elippsis from 'design/icons/Elippsis'
import EyeIcon from 'design/icons/Frame'
import { getDocumentTypes, uploadKYC } from 'agents/agents.api.client'
import { useRejectKYC, useAcceptKYC, useUpdateDocumentStatus } from '../hooks'
import { pascalCase } from 'lib/formatters'
import Select from 'design/elements/Select'
import ImageViewer from 'design/elements/ImageViewer'
import Button from 'design/elements/Button'
import snakeCase from 'lodash.snakecase'
import Box from 'design/elements/Box'
import Text from 'design/elements/Text'
import Divider from 'design/elements/Divider'
import TextField from 'design/elements/TextField'
import Modal from 'design/elements/Modal'
import Panel from 'design/elements/Panel'
import IconBtn from 'design/elements/IconBtn'
import Close from 'design/assets/images/close.svg'
import { useForm } from 'react-hook-form'
import Spacer from 'design/elements/Spacer'
import {
  useChangeID,
  useChangeCACStatusReport,
  useChangeCAC,
  useChangeAddress,
  useChangeAllotmentOfShares,
  useChangeParticularsOfDirectors,
  useChangeBoardResolutionConsent,
  useChangeEvidenceOfPOSActiveRate,
  useChangeCacCertifiedApplicationForm,
  useChangeAggregatorProofOfEvidence,
  useChangeMermat,
  useChangeIncorporatedTrusteesForm,
  useChangeConstitution,
  useChangeTransactionValueCommissionProof,
  useChangePassport,
} from 'agents/hooks/useUploadAgentDocuments'

import useViewKYCDocumentsActions from 'agents/hooks/useUpdateKYCDocumentsActions'
import { useToggle } from 'lib/hooks/useToggle'
import RejectReasonModal from 'agents/modals/RejectReasonModal'

const AgentKycDocuments = ({ agent, notify }) => {
  const { data: DocumentOpts, status: docOptsStatus } = useQuery(['get-document-types'], () => getDocumentTypes(), {
    staleTime: 60 * 60 * 60,
  })

  const form = useForm()
  const history = useHistory()
  const [selectedIDType, setSelectedIDType] = useState('')
  const [currentId, setCurrentId] = useState('IdentityType')
  const [rejectReason, setRejectReason] = useState('')
  const [rejectReasonDef, setRejectReasonDef] = useState('')
  const [rejectModal, setRejectModal] = useToggle(false)
  const [refName, setRefName] = useState('')
  const [docStat, setDocStat] = useToggle(false)

  const files = React.useRef({
    identity: agent?.id_card,
    passport: agent?.passport,
    evidence_of_address: agent?.evidence_of_address,
    certificate_of_incorporation: agent?.certificate_of_incorporation,
    primary_id_doc: agent?.primary_id_doc,
    c_a_c_status_report: agent?.c_a_c_status_report,
    allotment_of_shares: agent?.allotment_of_shares,
    particulars_of_director: agent?.particulars_of_director,
    particulars_of_proprietor: agent?.particulars_of_proprietor,
    board_resolution_consenting_to_with_fair_money: agent?.board_resolution_consenting_to_with_fair_money,
    evidence_of_p_o_s_active_rate: agent?.evidence_of_p_o_s_active_rate,
    cac_certified_application_form: agent?.cac_certified_application_form,
    m_e_m_a_r_t: agent?.m_e_m_a_r_t,
    incorporated_trustees_application_form: agent?.incorporated_trustees_application_form,
    constitution: agent?.constitution,
  })

  const { rejecting, run } = useRejectKYC((_, ref) => {
    files.current[snakeCase(ref).toLowerCase()] = null
  })

  const { accepting, accepts } = useAcceptKYC((_, ref) => {
    files.current[snakeCase(ref).toLowerCase()] = null
  })

  const reject = useCallback(
    (ref, rejectReason) => {
      return () => {
        run({ agentid: agent.agent_id, ref, rejectReason })
        setTimeout(() => history.go(0), 3000)
      }
    },
    [run, agent, history]
  )

  const accept = useCallback(
    (ref) => {
      return () => {
        accepts({ agentid: agent.agent_id, ref, currentId: selectedIDType })
        setTimeout(() => history.go(0), 3000)
      }
    },
    [accepts, agent]
  )

  const { handleAccept, handleReject, data, isLoading } = useUpdateDocumentStatus()

  const [valueNumber, setValueNumber] = useState('')
  const [idfile, setidfile] = useState('')

  const documentTypeList = React.useMemo(() => {
    return DocumentOpts?.map(({ id, name }) => ({
      value: id,
      name: name,
    }))
  }, [DocumentOpts])

  // identity_type
  const getDocumentType = (type) => {
    return (
      <TextField
        label="Document Type"
        defaultValue={
          type === 1
            ? 'NIN'
            : type === 2
            ? 'INTERNATIONAL PASSPORT'
            : type === 3
            ? 'DRIVERS LICENSE'
            : type === 4
            ? 'VOTERS CARD'
            : 'NO DOCUMENT'
        }
        disabled={true}
      />
    )
  }
  const individualAccount = agent.business_account_type === 10
  const corporateAccount = agent.business_account_type === 20
  const changeID = useChangeID(agent, selectedIDType, valueNumber, notify)
  const changeCACStatusReport = useChangeCACStatusReport(agent, notify, history, setidfile)
  const changeCAC = useChangeCAC(agent, notify)
  const changeAddress = useChangeAddress(agent, notify, history)
  const changeAllotmentOfShares = useChangeAllotmentOfShares(agent, notify, history)
  const changeParticularsOfDirectors = useChangeParticularsOfDirectors(agent, notify, history)
  const changeBoardResolutionConsent = useChangeBoardResolutionConsent(agent, notify, history)
  const changeEvidenceOfPOSActiveRate = useChangeEvidenceOfPOSActiveRate(notify, agent, history)
  const changeAggregatorProofOfEvidence = useChangeAggregatorProofOfEvidence(notify, agent, history)
  const changeCacCertifiedApplicationForm = useChangeCacCertifiedApplicationForm(agent, notify, history)
  const changeMermat = useChangeMermat(agent, notify, history)
  const changeIncorporatedTrusteesForm = useChangeIncorporatedTrusteesForm(agent, notify, history)
  const changeConstitution = useChangeConstitution(agent, notify, history)
  const changeTransactionValueCommissionProof = useChangeTransactionValueCommissionProof(agent, notify, history)
  const changePassport = useChangePassport(agent, notify, history)

  const [state, { applyToggle }] = useViewKYCDocumentsActions()
  const showModal = (doc, ref) => {
    setRejectModal()
    setDocStat(doc)
    setRefName(ref)
  }

  return (
    <>
      {rejectModal && (
        <Modal close={setRejectModal}>
          <Box width="500px" fontSize="sm">
            <Panel px="xl" py="md" display="flex" justifyContent="space-between" alignItems="center">
              <Text>Reject Reason</Text>
              <IconBtn onClick={setRejectModal} color="#8b9ba8">
                <img src={Close} alt="close" />
              </IconBtn>
            </Panel>
            <RejectReasonModal
              setRejectReason={setRejectReason}
              setRejectReasonDef={setRejectReasonDef}
              rejectReason={rejectReason}
              rejectReasonDef={rejectReasonDef}
              refName={refName}
              reject={reject}
              docStat={docStat}
              agentDocumentId={agent.agent_document_id}
              handleReject={handleReject}
            />
          </Box>
        </Modal>
      )}
      <Box py="lg">
        <Text textTransform="uppercase" fontWeight="600" fontSize="16px" mb="sm">
          {individualAccount
            ? 'Individual account upgrade details'
            : corporateAccount
            ? 'Corporate account upgrade details'
            : 'Account upgrade details'}
        </Text>

        <Text fontWeight="400" fontSize="14px" color="#8095A7">
          User will Gain access to daily transaction limit of 5 million
        </Text>
        <Divider />
        <Spacer />
        <Box background="#F5F8FA" px="sm" py="sm">
          <Text fontWeight="400" fontSize="14px" color="#8095A7">
            ID verification
          </Text>
        </Box>
        <Spacer mt="sm" />
        <TextField
          label="NIN (National Identity Number)"
          name="identity_number"
          error={form.errors?.identity_number}
          helperText={form.errors?.identity_number?.message && 'Please enter a  valid identity code'}
          defaultValue={agent?.identity_number}
          onChange={(e) => setValueNumber(e.target.value)}
          disabled={agent.id_verify}
        />

        <Spacer mt="lg" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            NIN (National Identity Card)
          </Text>
          <Box
            width="61px"
            height="23px"
            padding="4px 8px"
            backgroundColor={agent.id_verify ? '#52BE95' : '#D8314F'}
            borderRadius="12px"
            color="white"
            fontSize="12px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {agent.id_verify ? 'Approved' : 'Rejected'}
          </Box>
        </Box>
        <Spacer mt="1em" />
        <Select
          options={DocumentOpts}
          loading={!DocumentOpts?.length}
          label="Type of Document"
          onChange={(option) => setSelectedIDType(option.id)}
          value={selectedIDType}
          valueField="name"
        />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.id_card ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.id_card?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeID(file)}
            accept=".pdf,.jpg,.png"
            name="identity"
            fileName="identity"
            label={agent.id_card === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.id_card = file
              form.setValue('id_card', file)
              close && close()
              setCurrentId('IdentityType')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.id_card && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showIdentityCardDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.id_card && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showIdentityCardActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}
          {state.showIdentityCardActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={accept('Identity')}
                  loading={['Identity'].includes(accepting)}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(false, 'Identity')}
                    loading={['Identity'].includes(rejecting)}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showIdentityCardDoc && <ImageViewer src={agent.id_card} />}
        <Spacer mt="xl" />
        <Box background="#F5F8FA" px="sm" py="sm">
          <Text fontWeight="400" fontSize="14px" color="#8095A7">
            Proof of address
          </Text>
        </Box>
        <Spacer mt="lg" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            Proof of address
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.evidence_of_address ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.evidence_of_address?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeAddress(file)}
            name="evidence_of_address"
            accept=".pdf,.jpg,.png"
            fileName="evidence_of_address"
            label={agent.evidence_of_address === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.evidence_of_address = file
              form.setValue('evidence_of_address', file)
              close && close()
              setCurrentId('EvidenceOfAddress')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.evidence_of_address && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => {
                  applyToggle('showEvidenceofAddressDoc')
                }}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}

          {agent.evidence_of_address && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showEvidenceofAddressActions')}
              >
                <Elippsis column={true} />
                <Text pl="0.5em">Actions</Text>
              </Button>
            </Box>
          )}
          {state.showEvidenceofAddressActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={accept(pascalCase('evidence_of_address'))}
                  loading={[pascalCase('evidence_of_address')].includes(accepting)}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(false, pascalCase('evidence_of_address'))}
                    loading={[pascalCase('evidence_of_address')].includes(rejecting)}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        {state.showEvidenceofAddressDoc && <ImageViewer src={agent.evidence_of_address} />}
      </Box>
      <Box py="lg">
        <Text textTransform="uppercase" fontWeight="600" fontSize="16px" mb="sm">
          {individualAccount ? 'Upgrading fMb account to Aggregator' : 'corporate Account Kyc 4 Details'}
        </Text>

        <Text fontWeight="400" fontSize="14px" color="#8095A7">
          {individualAccount
            ? ' User will earn extra for every new agent or merchant you successfully refer User gains access to more '
            : 'unlimited transaction limit (Up to maximum daily limit of 10million)'}
        </Text>
        <Divider />
        <Spacer />
        <Box background="#F5F8FA" px="sm" py="sm">
          <Text fontWeight="400" fontSize="14px" color="#0F1C27">
            {individualAccount
              ? 'Preferred verification proof'
              : ' Verification proof for (Up to maximum daily limit of 10million)'}
          </Text>
        </Box>
        <Spacer mt="xl" />

        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            Passport
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.passport ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.passport?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changePassport(file)}
            name="passport"
            accept=".pdf,.jpg,.png"
            fileName="passport"
            label={agent.passport === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.passport = file
              form.setValue('Passport', file)
              close && close()
              setCurrentId('Passport')
              setidfile(file)
            }}
            width="30%"
          />

          {agent.passport && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showPassportDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.passport && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showPassportActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}

          {state.showPassportActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={accept(pascalCase('Passport'))}
                  loading={[pascalCase('Passport')].includes(accepting)}
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(false, pascalCase('Passport'))}
                    loading={[pascalCase('Passport')].includes(rejecting)}
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showPassportDoc && <ImageViewer src={agent.passport} />}

        <Spacer mt="xl" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            Evidence of POS active rate of 70%
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.evidence_of_p_o_s_active_rate ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.evidence_of_p_o_s_active_rate?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeEvidenceOfPOSActiveRate(file)}
            name="evidence_of_p_o_s_active_rate"
            accept=".pdf,.jpg,.png"
            fileName="evidence_of_p_o_s_active_rate"
            label={agent.evidence_of_p_o_s_active_rate === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.evidence_of_p_o_s_active_rate = file
              form.setValue('evidence_of_p_o_s_active_rate', file)
              close && close()
              setCurrentId('EvidenceOfPOSActiveRateFile')
              setidfile(file)
            }}
            width="30%"
          />

          {agent.evidence_of_p_o_s_active_rate && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showPOSVerificationDocs')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.evidence_of_p_o_s_active_rate && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showPosVerificationActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}

          {state.showPosVerificationActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.evidence_of_p_o_s_active_rate_id,
                      document_status: 1,
                    })
                    applyToggle('showPosVerificationActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.evidence_of_p_o_s_active_rate_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showPOSVerificationDocs && <ImageViewer src={agent.evidence_of_p_o_s_active_rate} />}

        <Spacer mt="xl" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            Proof of evidence of aggregator on other platform
          </Text>
        </Box>
        <Spacer mt="1em" />

        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.aggregator_proof_of_evidence ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.aggregator_proof_of_evidence?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeAggregatorProofOfEvidence(file)}
            name="aggregator_proof_of_evidence"
            accept=".pdf,.jpg,.png"
            fileName="aggregator_proof_of_evidence"
            label={agent.aggregator_proof_of_evidence === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.aggregator_proof_of_evidence = file
              form.setValue('aggregator_proof_of_evidence', file)
              close && close()
              setCurrentId('AggregatorProofOfEvidenceFile')
              setidfile(file)
            }}
            width="30%"
          />

          {agent.aggregator_proof_of_evidence && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showAggregatorProofOfEvidenceDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.aggregator_proof_of_evidence && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showAggregatorProofOfEvidenceActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}

          {state.showAggregatorProofOfEvidenceActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.aggregator_proof_of_evidence_id,
                      document_status: 1,
                    })
                    applyToggle('showAggregatorProofOfEvidenceActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.aggregator_proof_of_evidence_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showAggregatorProofOfEvidenceDoc && <ImageViewer src={agent.aggregator_proof_of_evidence} />}
        <Spacer mt="xl" />

        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            Transaction value and Commission Proof
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.transaction_value_commission_proof ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.transaction_value_commission_proof?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeTransactionValueCommissionProof(file)}
            name="transaction_value_commission_proof"
            accept=".pdf,.jpg,.png"
            fileName="transaction_value_commission_proof"
            label={agent.transaction_value_commission_proof === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.transaction_value_commission_proof = file
              form.setValue('transaction_value_commission_proof', file)
              close && close()
              setCurrentId('TransactionValueCommissionProofFile')
              setidfile(file)
            }}
            width="30%"
          />

          {agent.transaction_value_commission_proof && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showTransactionValueProofDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.transaction_value_commission_proof && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showTransactionValueProofActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}

          {state.showTransactionValueProofActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.transaction_value_commission_proof_id,
                      document_status: 1,
                    })
                    applyToggle('showTransactionValueProofActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.transaction_value_commission_proof_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showTransactionValueProofDoc && <ImageViewer src={agent.transaction_value_commission_proof} />}

        <Spacer mt="xl" />

        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            CAC Document (Certificate of Incorporation)
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.certificate_of_incorporation ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.certificate_of_incorporation?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeCAC(file)}
            name="certificate_of_incorporation"
            accept=".pdf,.jpg,.png"
            fileName="certificate_of_incorporation"
            label={agent.certificate_of_incorporation === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.certificate_of_incorporation = file
              form.setValue('certificate_of_incorporation', file)
              close && close()
              setCurrentId('cac')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.certificate_of_incorporation && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showCACDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.certificate_of_incorporation && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showCACVerificationActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}
          {state.showCACVerificationActions && (
            <Box width="40%">
              <Box width="100%">
                <Box variant="green" width="100%">
                  <Button
                    type="button"
                    variant="fairmoney"
                    fullWidth
                    onClick={accept(pascalCase('Cac'))}
                    loading={[pascalCase('c_a_c_document')].includes(accepting)}
                  >
                    Approve
                  </Button>
                </Box>
              </Box>

              <Box pt="2px" width="100%">
                <Box variant="blue" width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(false, pascalCase('Cac'))}
                    loading={[pascalCase('c_a_c_document')].includes(rejecting)}
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showCACDoc && <ImageViewer src={agent.certificate_of_incorporation} />}
        <Spacer mt="xl" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            CAC (Status Report)
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.c_a_c_status_report ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.c_a_c_status_report?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeCACStatusReport()}
            name="c_a_c_status_report"
            accept=".pdf,.jpg,.png"
            fileName="c_a_c_status_report"
            label={agent.c_a_c_status_report === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.c_a_c_status_report = file
              form.setValue('c_a_c_status_report', file)
              close && close()
              setCurrentId('CACStatusReportFile')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.c_a_c_status_report && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showCACStatusReportDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.c_a_c_status_report && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showCACStatusReportActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}
          {state.showCACStatusReportActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.c_a_c_status_report_doc_id,
                      document_status: 1,
                    })
                    applyToggle('showCACStatusReportActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.c_a_c_status_report_doc_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showCACStatusReportDoc && <ImageViewer src={agent.c_a_c_status_report} />}
        <Spacer mt="xl" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            CAC (Certified Application Form)
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.cac_certified_application_form ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.cac_certified_application_form?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeCacCertifiedApplicationForm(file)}
            name="cac_certified_application_form"
            accept=".pdf,.jpg,.png"
            fileName="cac_certified_application_form"
            label={agent.cac_certified_application_form === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.cac_certified_application_form = file
              form.setValue('cac_certified_application_form', file)
              close && close()
              setCurrentId('CacCertifiedApplicationFormFile')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.cac_certified_application_form && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showCacCertifiedApplicationDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.cac_certified_application_form && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showCacCertifiedApplicationActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}
          {state.showCacCertifiedApplicationActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.cac_certified_application_form_id,
                      document_status: 1,
                    })
                    applyToggle('showCacCertifiedApplicationActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.cac_certified_application_form_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showCacCertifiedApplicationDoc && <ImageViewer src={agent.cac_certified_application_form} />}
        <Spacer mt="xl" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            CAC 2 (Allotment of shares)
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.allotment_of_shares ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.allotment_of_shares?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeAllotmentOfShares(file)}
            name="allotment_of_shares"
            accept=".pdf,.jpg,.png"
            fileName="allotment_of_shares"
            label={agent.allotment_of_shares === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.allotment_of_shares = file
              form.setValue('allotment_of_shares', file)
              close && close()
              setCurrentId('AllotmentOfSharesFile')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.allotment_of_shares && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showAllotmentOfSharesDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.allotment_of_shares && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showAllotmentOfSharesActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}
          {state.showAllotmentOfSharesActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.allotment_of_shares_doc_id,
                      document_status: 1,
                    })
                    applyToggle('showAllotmentOfSharesActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.allotment_of_shares_doc_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showAllotmentOfSharesDoc && <ImageViewer src={agent.allotment_of_shares} />}
        <Spacer mt="xl" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            CAC 7 (Particulars of Directors)
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.particulars_of_director ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.particulars_of_director?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeParticularsOfDirectors(file)}
            name="particulars_of_director"
            accept=".pdf,.jpg,.png"
            fileName="particulars_of_director"
            label={agent.particulars_of_director === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.particulars_of_director = file
              form.setValue('particulars_of_director', file)
              close && close()
              setCurrentId('ParticularsOfDirectorsFile')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.particulars_of_director && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showParticularsOfDirectorsDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.particulars_of_director && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showParticularsOfDirectorsActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}
          {state.showParticularsOfDirectorsActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.particulars_of_director_doc_id,
                      document_status: 1,
                    })
                    applyToggle('showParticularsOfDirectorsActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.particulars_of_director_doc_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showParticularsOfDirectorsDoc && <ImageViewer src={agent.particulars_of_director} />}
        <Spacer mt="xl" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            MEMART (Memorandum and Article of Association)
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.m_e_m_a_r_t ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.m_e_m_a_r_t?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeMermat(file)}
            name="m_e_m_a_r_t"
            accept=".pdf,.jpg,.png"
            fileName="m_e_m_a_r_t"
            label={agent.m_e_m_a_r_t === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.m_e_m_a_r_t = file
              form.setValue('m_e_m_a_r_t', file)
              close && close()
              setCurrentId('MEMARTFile')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.m_e_m_a_r_t && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showMermatDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.m_e_m_a_r_t && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showMermatActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}
          {state.showMermatActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.m_e_m_a_r_t_id,
                      document_status: 1,
                    })
                    applyToggle('showMermatActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.m_e_m_a_r_t_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showMermatDoc && <ImageViewer src={agent.m_e_m_a_r_t} />}
        <Spacer mt="xl" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            Incorporated trustees application form
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.incorporated_trustees_application_form ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.incorporated_trustees_application_form?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeIncorporatedTrusteesForm(file)}
            name="incorporated_trustees_application_form"
            accept=".pdf,.jpg,.png"
            fileName="incorporated_trustees_application_form"
            label={agent.incorporated_trustees_application_form === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.incorporated_trustees_application_form = file
              form.setValue('incorporated_trustees_application_form', file)
              close && close()
              setCurrentId('IncorporatedTrusteesApplicationFormFile')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.incorporated_trustees_application_form && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showIncorporatedTrusteesForm')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.incorporated_trustees_application_form && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showIncorporatedTrusteesFormActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}
          {state.showIncorporatedTrusteesFormActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.incorporated_trustees_application_form_id,
                      document_status: 1,
                    })
                    applyToggle('showIncorporatedTrusteesFormActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.incorporated_trustees_application_form_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showIncorporatedTrusteesForm && <ImageViewer src={agent.incorporated_trustees_application_form} />}
        <Spacer mt="xl" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            Constitution
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.constitution ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.constitution?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeConstitution(file)}
            name="constitution"
            accept=".pdf,.jpg,.png"
            fileName="constitution"
            label={agent.constitution === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.constitution = file
              form.setValue('constitution', file)
              close && close()
              setCurrentId('ConstitutionFile')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.constitution && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showConstitutionDoc')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.constitution && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showConstitutionActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}
          {state.showConstitutionActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.constitution_id,
                      document_status: 1,
                    })
                    applyToggle('showConstitutionActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.constitution_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showConstitutionDoc && <ImageViewer src={agent.constitution} />}
        <Spacer mt="xl" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text color="#0F1C27" fontSize="14px" fontWeight="14px">
            Board resolution doc authorizing account opening with FairMoney
          </Text>
        </Box>
        <Spacer mt="1em" />
        <Box
          border="1px solid #CADCE0"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent={agent.board_resolution_consenting_to_with_fair_money ? 'space-between' : 'flex-end'}
          px="6px"
          py="10px"
        >
          <SheetUploader
            error={Boolean(form.errors.board_resolution_consenting_to_with_fair_money?.message)}
            loading={['Identity'].includes(accepting)}
            saveFn={(file) => changeBoardResolutionConsent(file)}
            name="board_resolution_consenting_to_with_fair_money"
            accept=".pdf,.jpg,.png"
            fileName="board_resolution_consenting_to_with_fair_money"
            label={agent.board_resolution_consenting_to_with_fair_money === null ? 'upload' : 'change'}
            onChange={([file], close) => {
              files.current.board_resolution_consenting_to_with_fair_money = file
              form.setValue('board_resolution_consenting_to_with_fair_money', file)
              close && close()
              setCurrentId('BoardResolutionConsentWithFairMoneyFile')
              setidfile(file)
            }}
            width="30%"
          />
          {agent.board_resolution_consenting_to_with_fair_money && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="blue"
                fullWidth
                loading={isLoading}
                onClick={() => applyToggle('showBoardResolution')}
              >
                <Text color="white" pr="0.5em">
                  View
                </Text>
                <EyeIcon />
              </Button>
            </Box>
          )}
          {agent.board_resolution_consenting_to_with_fair_money && (
            <Box width="30%" display="flex" gridGap="3px">
              <Button
                type="button"
                variant="default"
                fullWidth
                onClick={() => applyToggle('showBoardResolutionActions')}
                loading={isLoading}
              >
                <Elippsis column={true} />
                Action
              </Button>
            </Box>
          )}
          {state.showBoardResolutionActions && (
            <Box width="40%">
              <Box width="100%">
                <Button
                  type="button"
                  variant="fairmoney"
                  fullWidth
                  onClick={() => {
                    handleAccept({
                      agent_document_id: agent.board_resolution_consenting_to_with_fair_money_doc_id,
                      document_status: 1,
                    })
                    applyToggle('showBoardResolutionActions')
                  }}
                  loading={isLoading}
                  width="full"
                >
                  Approve
                </Button>
              </Box>

              <Box pt="2px">
                <Box width="100%">
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => showModal(true, agent.board_resolution_consenting_to_with_fair_money_doc_id)}
                    loading={isLoading}
                    width="full"
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {state.showBoardResolution && <ImageViewer src={agent.board_resolution_consenting_to_with_fair_money} />}
      </Box>
    </>
  )
}

export default AgentKycDocuments
