import { makeClient } from 'lib/client'
import { TOKEN_KEY } from 'auth/context/Auth.Context'
import { prodAlternateClient, handleResponse } from 'virtualAccounts/Virtual.accounts.api.client'

import { axios, axios2, pickErrorMessage, pickResult } from 'lib/axios.client'
import client from 'lib/client'
import makeForm from 'lib/makeFormData'
import toQueryString from 'lib/queryString'
import { X_REQUEST_LOCK } from 'lib/config'

const headersConfig = {
  headers: {
    'X-REQUEST-LOCK': X_REQUEST_LOCK,
  },
}
export const fetchAgentStatuses = () => axios.get(`i/agents/GetAgentStatus`).then(pickResult, pickErrorMessage)
export const fetchFieldStaff = () => axios.get(`i/agents/RetrieveFieldStaffs`).then(pickResult, pickErrorMessage)
export const fetchAgentStatements = (params) =>
  axios.get(`t/transaction/AgentStatements`, { params }).then(pickResult, pickErrorMessage)

export const fetchTerminalAccounts = (agentid) =>
  handleResponse(
    prodAlternateClient.get(
      `bas/virtualaccount/agent/allaccounts`,
      { agentid },
      { headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK } }
    )
  )

export const fetchAgentStatementsExportOptions = () =>
  axios.get(`t/transaction/AgentStatementExportOptions`).then(pickResult, pickErrorMessage)
export const fetchAgentTypes = () => axios.get(`i/agents/GetAgentType`).then(pickResult, pickErrorMessage)
export const fetchAgentCategories = () => axios.get(`i/agents/GetAgentCategory`).then(pickResult, pickErrorMessage)
export const fetchAgentCampaigns = () => axios.get(`i/campaigns/GetCampaign`).then(pickResult, pickErrorMessage)
export const fetchAgents = (params) => axios.get(`i/agents/AgentList`, { params }).then(pickResult, pickErrorMessage)
export const fetchAllAgents = (params) =>
  axios.get(`i/agents/RetrieveAllAgentsUsernameAndIdOnly`, { params }).then(pickResult, pickErrorMessage)
export const fundAgentWallet = (body) =>
  axios.post(`t/transaction/FundAgentWallet`, body).then(pickResult, pickErrorMessage)
export const posDeviceDepositAndDebit = (body) =>
  axios.post(`t/transaction/PosDeviceDeposit`, body).then(pickResult, pickErrorMessage)
export const posFeeRefund = (body) => axios.post(`t/transaction/PosFeeRefund`, body).then(pickResult, pickErrorMessage)
export const fetchAllPOSDepositList = (params) =>
  axios.get(`t/transaction/RetrievePosDeviceDepositList`, { params }).then(pickResult, pickErrorMessage)
export const fetchAgentPOSDepositList = (params) =>
  axios.get(`t/transaction/RetrieveAgentPosDeviceDepositList`, { params }).then(pickResult, pickErrorMessage)
export const fetchAgentDetails = (agentId) =>
  axios.get(`i/agents/AgentDetail`, { params: { agentId } }).then(pickResult, pickErrorMessage)

export const assignSubAgents = (body) =>
  axios.post(`i/agents/MapSubAgentsToSponsor`, body).then(pickResult, pickErrorMessage)
export const assignToFieldStaff = (body) =>
  axios.post(`i/agents/MapAgentToFieldStaffs`, body).then(pickResult, pickErrorMessage)
export const verifyBvn = (params) => axios.get(`i/agents/VerifyBVN`, { params }).then(pickResult, pickErrorMessage)

export const manuallyVerifyBvn = (body) => {
  const formData = makeForm(body)
  return axios.post(`i/agents/manuallyVerifyAgentBvn`, formData).then(pickResult, pickErrorMessage)
}

export const uploadKYC = (body) => {
  const formData = makeForm(body)
  return axios.post(`i/agents/UploadKyc  `, formData)
}

export const rejectKYC = (params) => axios.get(`i/agents/RejectKyc`, { params }).then(pickResult, pickErrorMessage)
export const acceptKYC = (params) => axios.get(`i/agents/ApproveKyc`, { params }).then(pickResult, pickErrorMessage)

export const updateAgentDocument = (body) =>
  axios.post(`i/agents/ApproveAgentDocument`, body).then(pickResult, pickErrorMessage)

export const updateAgentDocumentStatus = (body) => client.post(`i/agents/updateAgentDocumentStatus`, body)

export const fetchRejectReason = () => client.get(`i/agents/KycRejectDocumentReason`)

export const saveBvn = (body) => axios.post(`i/agents/SaveBVN`, body).then(pickResult, pickErrorMessage)
export const createMonifyAccounts = (params) =>
  axios.get(`i/agents/UpdateVirtualAccount`, { params }).then(pickResult, pickErrorMessage)
export const createAndUpdateVirtualAccount = (params) =>
  axios.post(`i/agents/UpdateVirtualAccount${toQueryString(params)}`).then(pickResult, pickErrorMessage)
export const exportAgentsWithPOS = () => axios.get(`p/terminal/agentwithpos`).then(pickResult, pickErrorMessage)
export const categorizeAgents = (body) =>
  axios.post(`i/agents/CategorizeAgentByTransactionsCountOrVolume`, body).then(pickResult, pickErrorMessage)
export const fetchAccoountTypes = () => client.get(`i/agents/GetAccountType`)

export const updateAgentDetails = (details) => {
  const formData = makeForm(details)
  return axios.patch('i/agents/UpdateAgentDetail', formData).then(pickResult, pickErrorMessage)
}
export const fetchAgentCategorizations = (params) =>
  axios.get(`i/agents/RetrieveAgentCategorizationSetup`, { params }).then(pickResult, pickErrorMessage)
export const setupAgentCategorization = (body) =>
  axios.post(`i/agents/SetupAgentCategorization`, body).then(pickResult, pickErrorMessage)
export const fetchSuperAgents = () =>
  axios.get(`i/agents/RetrieveAgentsorAggregators`).then(pickResult, pickErrorMessage)
export const fetchSubAgents = () => axios.get(`i/agents/RetrieveSubagents`).then(pickResult, pickErrorMessage)
export const fetchAgentTerminals = (params) =>
  axios.get(`p/terminal/GetTerminalSerialNumberListForPOSSalesOrDebit`, { params }).then(pickResult, pickErrorMessage)
export const fetchPOSTerminals = (params) =>
  axios.get(`p/terminal/RetrieveListOfMapTerminals`, { params }).then(pickResult, pickErrorMessage)
export const fetchAgentTerminalHistory = (params) =>
  axios.get(`p/terminal/RetrieveTerminalHistoryForAgent`, { params }).then(pickResult, pickErrorMessage)
export const getPOSDepositHistory = (params) =>
  axios.get(`p/terminal/RetrieveAgentPosDepositHistory`, { params }).then(pickResult, pickErrorMessage)
export const retrieveTerminalsMappedToAgent = (params) =>
  axios.get(`p/terminal/RetrieveTerminalsMappedToAgent`, { params }).then(pickResult, pickErrorMessage)
export const generateFieldStaffReport = (params) =>
  axios.get(`i/agents/GenerateFieldStaffReport`, { params }).then(pickResult, pickErrorMessage)
export const unAssignFromSponsor = (params) =>
  axios.post(`i/agents/UnmapAgentFromSponsor`, params).then(pickResult, pickErrorMessage)
export const liftTransactionLimit = (params) =>
  axios.get(`i/agents/deactivateAgentTemporaryTransactionLimit`, { params }).then(pickResult, pickErrorMessage)
export const freezeReason = (agentId) => client.get(`i/agents/GetAgentFreezeAccountDetails`, { agentId })
export const sendOTPToAgents = (body) => {
  const formData = makeForm(body)
  return axios.post(`i/agents/SendAccessCodeToAgent`, formData)
}

export const fetchPOSFees = (params) => axios.get(`p/terminal/GetAllFee`, { params }).then(pickResult, pickErrorMessage)

export const AddPOSFee = (body) => axios.post(`p/terminal/AddPosFee`, body).then(pickResult, pickErrorMessage)
export const DeletePosDepositFee = () =>
  axios.delete(`p/terminal/DeletePosDepositFee`).then(pickResult, pickErrorMessage)

export const bulkAssignToFieldStaff = (body) => {
  const formData = makeForm(body)
  return axios.post(`i/agents/BulkMapToFieldStaff`, formData)
}

export const getDocumentTypes = () => client.get(`i/agents/GetIdentityTypes`)

export const creditFieldStaff = (body) => {
  const formData = makeForm(body)
  return axios.post(`t/transaction/CreditFieldStaff`, formData)
}

export const bulkCreditAgents = (body) => {
  const formData = makeForm(body)
  return axios.post(`i/agents/BulkMapToFieldStaff`, formData)
}

export const bulkDebitAgents = (body) => {
  const formData = makeForm(body)
  return axios.post(`t/transaction/BatchWalletDebit`, formData)
}

export const migrateAgents = () => {
  // const formData = makeForm(body)
  return Promise.resolve('Noop')
}

export const addAgentsToCampaign = (body) => {
  const formData = makeForm(body)
  return axios.post(`i/agents/AddAgentsToCampaign`, formData)
}
export const bulkAddLien = (body) => {
  const formData = makeForm(body)
  return axios.post(`i/agents/BulkAddAgentLiens`, formData)
}
export const bulkRemoveLien = (body) => {
  const formData = makeForm(body)
  return axios.post(`i/agents/BulkLiftAgentLiens`, formData)
}
export const bulkIncrease = (body) => {
  const formData = makeForm(body)
  return axios.post(`i/agents/BulkLimitIncrease  `, formData)
}

export const getVirtualAccountProvider = () => client.get(`i/agents/GetVirtualAccountProvider`)
export const getSearchByList = () => client.get('i/agents/GetSearchByList')
export const getSponsorMappingHistory = (query) => client.get('p/terminal/GetTerminalHistoryBySponsorId', query)
export const getAppManagers = (query) => client.get('i/account/TeamMembersList', query)
export const exportSponsorMappingHistory = () => client.get('p/terminal/GetTerminalHistoryBySponsorId?export=yes')

export const updateAgentBasicDetails = (body) => {
  return axios.patch(`i/agents/UpdateAgentBasicDetail`, body).then(pickResult, pickErrorMessage)
}

export const updateAgentBankDetails = (body) => {
  return axios.patch(`i/agents/UpdateAgentBankDetail`, body).then(pickResult, pickErrorMessage)
}

export const getAccountTypeForAggregatorDashboard = (query) =>
  client.get(`i/agents/GetAccountTypeForAggregatorDashboard`, query)

export const updateAgentBusinessDetails = (body) => {
  return axios.patch(`i/agents/UpdateAgentBusinessDetail`, body).then(pickResult, pickErrorMessage)
}

export const updateAgentInfoDetails = (body) => {
  return axios.patch(`i/agents/UpdateAgentInfoDetails`, body).then(pickResult, pickErrorMessage)
}

export const updateAgentNextOfKinDetails = (body) => {
  return axios.patch(`i/agents/UpdateNextOfKinDetails`, body).then(pickResult, pickErrorMessage)
}

export const updateAgentTransactionLimit = (body) => {
  return axios.patch(`i/agents/UpdateTransactionLimit`, body).then(pickResult, pickErrorMessage)
}

export const updateAgentTransferLimit = (body) => {
  return axios.post(`i/agents/saveAgentTransferLimit`, body, headersConfig).then(pickResult, pickErrorMessage)
}

export const updateAgentLienDetails = (body) => {
  return axios.patch(`i/agents/UpdateAgentLienDetails`, body).then(pickResult, pickErrorMessage)
}

export const updateAgentRecruiterDetails = (body) => {
  return axios.patch(`i/agents/UpdateRecruiterDetails`, body).then(pickResult, pickErrorMessage)
}

export const upragdeAgentToAggeratorOrFieldStaff = (body) => {
  return axios.patch(`i/agents/UpgradeAgentToAggretorOrFieldStaff`, body).then(pickResult, pickErrorMessage)
}

export const unfreezeAgentAccount = (body) => {
  return client(`i/agents/FreezeAgentAccount`, body, { method: 'PATCH' })
}

export const fetchAllBanks = () => handleResponse(prodAlternateClient.get('b/bank/GetBankList', {}, headersConfig))

export function getToken() {
  return JSON.parse(sessionStorage.getItem(TOKEN_KEY))
}

const passValue = (args) => args

const V2Client = makeClient(
  'https://gateforce-gateway-bo.azurewebsites.net/api/v2',
  {
    X_REQUEST_LOCK: X_REQUEST_LOCK,
    'App-Id': 'xH1pQ3lVXNT0wsUqGJ/s/vGCAKuy9uNR',
  },
  () => ({
    Authorization: ['Bearer', getToken()].join(' '),
  }),
  {
    onSuccess: passValue,
    onError: Promise.reject,
  }
)

export const verifyBankAccount = (params) => V2Client.get('t/mobile/transaction/customerinfo', params)

export const triggerAutoswipe = (query) => client.get('t/transaction/TriggerAutoSwipeForAgent', query)
export const updateAdditionalContactdetails = (body) =>
  axios.post(`i/agents/addOrEditAgentContact`, body).then(pickResult, pickErrorMessage)
export const getAgentsContact = (query) => client.get(`i/agents/getAgentsContact`, query)
export const terminalSwapReasons = () => client.get(`p/terminal/TerminalSwapReasons`)
export const verifyTerminalAvailability = (id, query) =>
  client.get(`p/terminal/${id}/verify-terminal-availablity${toQueryString(query)}`)
export const swapTerminal = (id, body) => {
  const formData = makeForm(body)
  return client(`p/terminal/${id}/swap-terminal`, formData)
}
 export const fetchEnterpriseAffiliateList = (query) =>
   client.get(`i/agents/FetchAffiliatesAgent${toQueryString(query)}`)
export const assignAffiliateToAgent = (query) => client.patch(`i/agents/AssignAffiliateToAgent${toQueryString(query)}`)
   export const fetchSponsorTerminal = (sponsorId, params) =>
     client.get(`p/terminal/RetrieveListOfUnMapTerminals/${sponsorId}`, params)
   export const fetchTerminalSwapCategory = () => client.get(`p/terminal/SwapCategories`)