import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, queryCache } from 'react-query'

/**
 * ? Local Imports
 */
import { useUpdateAgentLienDetails } from 'agents/hooks/useUpdateAgent'

import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Divider from 'design/elements/Divider'
import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'
import TextField from 'design/elements/TextField'

import { loading } from 'lib/formatters'
import { liftAgentLien } from 'lien/lien.client'
import { ShowTree } from 'lib/gate'
import { roles, AGENTTYPE_VIEW, LIEN_FORM_VIEW } from 'lib/models'
import { useAuthState } from 'auth/context/Auth.Context'

const LienForm = ({ agent, alert, clearAlert, notify }) => {
  const { user } = useAuthState()

  const form = useForm({
    mode: 'onBlur',
  })
  const [mutate, { status }] = useMutation(liftAgentLien)
  const [updateLienDetails, { lienDetailStatus }] = useUpdateAgentLienDetails({ alert, clearAlert, notify })

  const handleFormSubmit = React.useCallback(
    (values) => {
      const agentId = agent?.agent_id
      const _formBody = Object.assign({}, values, {
        lien_amount: Number.isFinite(+values.lien_amount) ? +values.lien_amount : null,
        agent_id: +agentId,
      })
      updateLienDetails(_formBody)
    },
    [agent, updateLienDetails]
  )

  const hasLien = React.useMemo(() => agent?.lien_amount > 0, [agent])

  const handleLiftLien = React.useCallback(() => {
    mutate(
      { agentId: +agent.agent_id },
      {
        onSuccess(res) {
          return new Promise((res) => {
            queryCache.refetchQueries('agent_details', { force: true })
            queryCache.refetchQueries('all-lien', { force: true })
            notify(typeof res === 'string' ? res : 'Operation successfull!')
          })
        },
        onError(err) {
          alert(typeof err === 'string' ? err : 'There was a problem performing your request')
        },
      }
    )
  }, [agent, mutate, alert, notify])

  return (
    <Box as="form" onSubmit={form.handleSubmit(handleFormSubmit)}>
      <Text>LIEN</Text>
      <Divider mt="0" />
      <Spacer mt="lg" />
      <TextField
        disabled={hasLien}
        label="Lien Amount"
        name="lien_amount"
        error={form.errors?.lien_amount}
        helperText={form.errors?.lien_amount && 'Please enter a numeric value'}
        ref={form.register({ required: false, pattern: { value: /[\d]+$/ } })}
        defaultValue={agent?.lien_amount}
      />
      <Spacer mt="lg" />
      <TextField
        disabled={hasLien}
        label="Lien Reason"
        name="lien_reason"
        error={form.errors?.lien_reason}
        helperText={form.errors?.lien_reason && 'Please enter a lien reason'}
        ref={form.register({ required: false })}
        defaultValue={agent?.lien_reason}
      />
      {hasLien && (
        <>
          <Spacer mt="lg" />
          <Box width="40%" ml="auto">
            <Button variant="dark" fullWidth type="button" onClick={handleLiftLien} disabled={loading(status)}>
              Lift Lien
            </Button>
          </Box>
        </>
      )}

      <ShowTree
        forRoles={[
          roles.OPPERATION_HEAD,
          roles.SUPPORT,
          roles.KYC_TEAM,
          roles.OPERATIONS_ASSISTANT,
          AGENTTYPE_VIEW.includes(user?.agent_username.toLowerCase()) && roles.POS_TEAM,
          LIEN_FORM_VIEW.includes(user?.agent_username.toLowerCase()) && roles.REGIONAL_RETENTION_MANAGER,
        ]}
      >
        <Button mt="lg" loading={loading(lienDetailStatus)} type="submit" variant="success" fullWidth>
          Save
        </Button>
      </ShowTree>
    </Box>
  )
}

export default LienForm
