import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { COMPLIANCE_BVN_VIEW } from 'lib/models'
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'

/**
 * ? Local Imports
 */
import CustomSelect, { TimeColumn, TimeSelector } from './CustomSelect'

import { useAllBanks } from 'agents/hooks/useAllBanks'
import { verifyBankAccount, triggerAutoswipe } from 'agents/agents.api.client'
import { useUpdateAgentBankDetails } from 'agents/hooks/useUpdateAgent'

import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Checkbox from 'design/elements/Checkbox'
import Divider from 'design/elements/Divider'
import FieldText from 'design/elements/FieldText'
import Select from 'design/elements/Select'
import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'
import TextField from 'design/elements/TextField'
import Panel from 'design/elements/Panel'

import { formatDate, loading } from 'lib/formatters'
import { ShowTree } from 'lib/gate'
import { roles } from 'lib/models'
import ToggleButton from 'design/elements/ToggleButton'
import { useToggle } from 'lib/hooks/useToggle'
import DayPicker from 'design/elements/DayPicker'
import { swipeAgentType } from 'agents/config'
import { useAuthState } from 'auth/context/Auth.Context'

const HOURS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const MINUTES = [0, 15, 30, 45]
const MERIDIAN = ['AM', 'PM']

const BankDetailsForm = ({ agent, alert, clearAlert, notify }) => {
  const { user } = useAuthState()
  const form = useForm({
    mode: 'all',
    defaultValues: Object.assign(
      {},
      {
        wants_funds_swiped: agent?.wants_funds_swiped,
        bank: agent?.bank,
        bvn: agent?.bvn,
        bank_code: agent?.bank_code,
        auto_swipe_time: agent?.auto_swipe_time,
        account: agent?.account,
      }
    ),
  })

  const [ALLNGBANKS] = useAllBanks()

  const [updateBankDetails, { status }] = useUpdateAgentBankDetails({ alert, clearAlert, notify })

  const timeString = agent?.auto_swipe_time
  const registering = useRef(false)
  const [wants_funds_swiped, setWantsFundsSwiped] = useToggle(agent?.wants_funds_swiped)
  const [want_auto_swipe_time, setAutoSwipeTime] = useState(agent?.wants_funds_swiped)
  const [swipe_main_account, setSwipeMainAccount] = useState(agent?.swip_fund_to_main_account_wallet)
  const [timeValues, setTimeValues] = useState({ Hours: '12', Minutes: '00', Meridian: 'AM' })
  const [accountInfo, setAccountInfo] = useState(undefined)
  const [errorLoadingInfo, setErrorLoadingInfo] = useState(false)

  const allNigerianBanks = Array.isArray(ALLNGBANKS.data) ? ALLNGBANKS.data : []
  const formattedTime = `${timeValues?.Hours}:${timeValues?.Minutes} ${timeValues?.Meridian}`

  const handleFormSubmit = useCallback(
    (_formValues) => {
      const agentId = agent?.agent_id
      const _formBody = Object.assign({}, _formValues, {
        agent_id: +agentId,
        wants_funds_swiped: wants_funds_swiped,
        set_auto_swipe_time: typeof formattedTime === 'string' && wants_funds_swiped ? true : false,
        swip_to_main_account: swipe_main_account,
        auto_swipe_time: formattedTime,
      })
      updateBankDetails({ ..._formBody, account_name: accountInfo?.full_name })
      setAccountInfo(undefined)
    },
    [agent?.agent_id, wants_funds_swiped, formattedTime, swipe_main_account, updateBankDetails, accountInfo?.full_name]
  )

  const convertTimeString = useCallback((time) => {
    const dateString = '2000-01-01 ' + time
    const date = new Date(dateString)

    let hours = date.getHours()
    const minutes = date.getMinutes()
    const meridian = hours >= 12 ? 'PM' : 'AM'

    // Convert to 12-hour format
    if (hours === 0) {
      hours = 12
    } else if (hours > 12) {
      hours -= 12
    }

    setTimeValues((prev) => ({
      ...prev,
      Hours: hours.toString().padStart(2, '0'),
      Minutes: minutes.toString().padStart(2, '0'),
      Meridian: meridian,
    }))
  }, [])

  useEffect(() => {
    if (!registering.current) {
      form.register({ name: 'wants_funds_swiped', type: 'custom', required: true })
      form.register({ name: 'swip_to_main_account', type: 'custom', required: true })
      form.register({ name: 'bank', type: 'custom', required: true })
      form.register({ name: 'bank_code', type: 'custom', required: true })
      form.register({ name: 'bvn', type: 'custom' })
    }
    return () => {
      registering.current = false
    }
  }, [form])

  useEffect(() => {
    if (typeof timeString === 'string') {
      convertTimeString(timeString)
    }
  }, [convertTimeString, timeString])

  const handleBankSelect = (option) => {
    form.setValue('bank', option.name)
    form.setValue('bank_code', option.bankCode)
  }

  const swipeText = wants_funds_swiped === true ? 'enabled' : 'disabled'
  const swipeBgColor = useMemo(() => {
    return wants_funds_swiped ? 'rgba(57,169,74, 0.2)' : 'rgba(233, 16, 16, 0.2)'
  }, [wants_funds_swiped])

  const swipeTextColor = useMemo(() => {
    return wants_funds_swiped ? 'green' : 'red'
  }, [wants_funds_swiped])

  const handleTimeChange = (e) => {
    const { name, value } = e.target
    setTimeValues((prev) => ({ ...prev, [name]: value }))
  }

  const { account, bank_code } = form.getValues()

  const { isFetching } = useQuery({
    queryKey: ['validate-account', { account, bank_code }],
    variables: [account, bank_code],
    queryFn: async () => {
      let res
      if (account?.length === 10 && bank_code !== (undefined || null)) {
        res = await verifyBankAccount({ customerID: account, paymentCode: bank_code, billerCode: 'ZCDT' })
        return res
      }
      return res
    },
    config: {
      onSuccess: (data) => {
        setAccountInfo(data?.result[0])
        setErrorLoadingInfo(false)
      },
      onError: () => {
        setErrorLoadingInfo(true)
      },
      retry: 2,
      refetchInterval: 600000,
      retryDelay: 30000,
    },
  })

  const [fetchData, setFetchData] = useState(false)
  const [date, _setDate] = useState()
  const setDate = (date) => _setDate(formatDate(date))

  useEffect(() => {
    if (fetchData) {
      triggerAutoswipe({ agentId: agent?.agent_id, walletDate: date })
        .then((res) => {
          notify(res)
        })
        .catch((_err) => {
          alert(_err || `There was a problem processing your request`)
        })
      setFetchData(false)
    }
  }, [agent?.agent_id, alert, date, fetchData, notify])

  return (
    <Box>
      <Box as="form" onSubmit={form.handleSubmit(handleFormSubmit)}>
        <Text textTransform="uppercase" fontWeight="bold">
          Bank
        </Text>
        <Divider />

        <Select
          label="Select Bank"
          options={allNigerianBanks}
          loading={loading(ALLNGBANKS.status) && ALLNGBANKS.isFetching}
          onChange={handleBankSelect}
          value={agent?.bank}
          valueField="name"
        />
        <Spacer mt="lg" />
        <TextField
          label="Account"
          name="account"
          error={form.errors?.account}
          helperText={form.errors?.account?.message && 'Please enter a valid account no'}
          ref={form.register({ required: true, minLength: 10, maxLength: 10, pattern: { value: /[\d]+$/ } })}
          defaultValue={agent?.account}
        />
        <Spacer mt="xs" />
        {errorLoadingInfo && (
          <Text color="red" fontSize="13px">
            error getting account information
          </Text>
        )}

        {accountInfo && Object.keys(accountInfo).length !== 0 ? (
          <FieldText isValue loading={isFetching}>
            {accountInfo?.full_name}
          </FieldText>
        ) : null}

        <Spacer mt="md" />
        <ShowTree
          forRoles={[
            roles.OPPERATION_HEAD,
            COMPLIANCE_BVN_VIEW.includes(user?.agent_username.toLowerCase()) && roles.SUPPORT,
          ]}
        >
          <TextField
            label="BVN"
            readOnly
            disabled={true}
            name="bvn"
            defaultValue={agent?.bvn}
            ref={form.register({ name: 'bvn', type: 'custom' })}
          />
        </ShowTree>
        <Spacer mt="md" />
        <Box>
          <Box background="#F5F8FA" p="sm">
            <Box mb="10px">
              <Box display="flex" alignItems="center" justifyContent="space-between" background="#F5F8FA">
                <Box>
                  <FieldText color="#0F1C27" fontWeight="bold" fontSize="12px">
                    AutoSwipe
                  </FieldText>
                  <FieldText color="#8095A7" fontWeight="400" fontSize="12px" mt="6px">
                    Enable/Disable AutoSwipe Feature
                  </FieldText>
                </Box>
                <ToggleButton
                  name="wants_funds_swiped"
                  id="wants_funds_swiped"
                  onChange={() => {
                    setWantsFundsSwiped((c) => !c)
                  }}
                  checked={wants_funds_swiped}
                />
              </Box>
              <Spacer mt="lg" />

              {swipeAgentType.includes(agent?.agent_type) && (
                <Checkbox
                  id="swip_to_main_account"
                  label={`Swipe funds to main account is ${swipeText}`}
                  name="swip_to_main_account"
                  onChange={() => setSwipeMainAccount((c) => !c)}
                  checked={swipe_main_account}
                />
              )}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                background="#E7EFF6"
                px="6px"
                py="8px"
              >
                <FieldText color="#8095A7" fontWeight="bold" fontSize="12px">
                  Swipe Time (Default)
                </FieldText>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                  <Checkbox
                    id="set_auto_swipe_time"
                    label=" Select Swipe time"
                    mx="sm"
                    name="set_auto_swipe_time"
                    onChange={() => setAutoSwipeTime((c) => !c)}
                    checked={want_auto_swipe_time}
                  />

                  <Box width="200px" position="relative" mx="auto" my="xs">
                    {want_auto_swipe_time === true ? (
                      <TimeSelector>
                        <CustomSelect
                          name="Hours"
                          value={timeValues?.Hours}
                          options={HOURS}
                          onChange={handleTimeChange}
                        />
                        <TimeColumn>:</TimeColumn>
                        <CustomSelect
                          name="Minutes"
                          value={timeValues?.Minutes}
                          options={MINUTES}
                          onChange={handleTimeChange}
                        />
                        <CustomSelect
                          name="Meridian"
                          value={timeValues?.Meridian}
                          options={MERIDIAN}
                          onChange={handleTimeChange}
                        />
                      </TimeSelector>
                    ) : null}
                  </Box>
                  <Box
                    width="130px"
                    textAlign="center"
                    px="3px"
                    py="8px"
                    borderRadius={5}
                    backgroundColor={'#D1DFEC'}
                    color="#1C3244"
                    fontWeight="bold"
                    fontSize="12px"
                  >
                    {formattedTime}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt="md"></Box>

        <ShowTree forRoles={[roles.OPPERATION_HEAD]}>
          <Button disabled={isFetching} mt="lg" loading={loading(status)} type="submit" variant="success" fullWidth>
            Save
          </Button>
        </ShowTree>
      </Box>

      <Panel my="xl" position="relative">
        <DayPicker value={date} onChange={setDate} />
        <Button
          variant="dark"
          onClick={(e) => {
            e.preventDefault()
            setFetchData(true)
          }}
          width="100%"
        >
          Trigger AutoSwipe
        </Button>
      </Panel>
    </Box>
  )
}
export default BankDetailsForm
